import React from "react";

export function OurStory() {
  return (
    <>
      <section className="banner">
        <img src="images/banner.png" alt="" />
        <div className="inner-content col-md-12">
          <div className="container">
            <h1>HUMBLE BEGINNINGS</h1>
            <span>A STORY OF LIFE</span>

            <p>
              How we became the farmers of the future, tilling the technology of
              tomorrow today.
            </p>
          </div>
        </div>
      </section>
      <section className="sec_include">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-8 adj_text">
              <h3>Sic Transit Gloria Mundi</h3>
              <p>
                At the deepest level of its core, technology embodies the circle
                of life. And from an old age Larry Shepard unlearned how to
                harness that circle and perpetuate it through more technology.
                He was graduating preschool, shaking his instructor’s hand while
                holding up his brand new diploma when he saw his mother drop her
                phone. The device caught a twinkle in Larry’s eyes as it fell,
                somehow still holding Larry perfectly in frame to immortialize
                the moment. That was the moment, the exact moment, when Larry
                knew what he would fix with his grant money. The world would
                never again feel the stinging pain experienced in one’s belly
                when a smart phone hits the ground. Never again would a parent
                have to worry about their two year old practicing their dropping
                skills with their pocket technology. Localized anti gravity was
                born and neither phone nor Larry ever touched the ground again.
              </p>
              <p style={{ paddingTop: "20px" }}>
                It has been nearly 40 years since Larry left us, but the impact
                of his genius is still felt today. They say that two brains are
                better than one, which is why Larry left us with copies of
                himself. Three perfect copies of his beautiful grey matter
                captured in the neural network technology that he helped
                perfect. Two brains are better than one, but nothing beats three
                Larrys.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 adj_text">
              <img src="images/shutterstock_252243790.png" alt="Public" />
              <div className="readtext">
                <h3>FIRST PAPER DATA CENTER</h3>
                <p>
                  The boxes were stacked high and dry with no order whatsoever.
                  The facility ran for 300 nano seconds before it disintegrated.
                  This is the only known surviving photograph.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_include1">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-8 adj_text">
              <h3>The Journey Continued</h3>
              <p>
                Larry may have been the innovative entreprenuerial spark that
                lit the fire of progress and unified the seven islands on this
                ball of water hurdling through space, but that fire still rages.
                From the gamma mutation labs on mercury to the cryolabs of Pluto
                and Eres, Globomantics barrels forward into the unkown, into the
                impossible, the endless wonder of thoughts unthought... into the
                future. As a leader of the sentient beings on this vibration of
                string theory we drive onward, boldly trying the untried and
                solving the unresolvable unsolvable.
              </p>
              <br />
              <br />

              <div className="paragraph-second">
                <h3>Earth-anity and Beyond</h3>
                <p>
                  It will be within our lifetime that lifetime ceases to exist.
                  Confusion will be untangled and conflicts defeated through
                  context. All lifeforms shall be unified and commited to a
                  vision of othe future. With the advent of the Doolittle
                  Algorithm, not even our dreams are the limit. A new level of
                  harmony, undreamable to our predecessors, carries us down the
                  stream of conciousnes and into the recesses of the deepest
                  shadows of the largest questions. What is it to be? For that
                  is in fact, the question.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 adj_text">
              <img src="images/shutterstock_564197512.png" alt="Public" />
              <div className="readtext">
                <h3>LARRY SHAPARD, CEO &amp; MAGICIAN</h3>
                <p>The man, the myth, the legend.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
